<template>
    <header :class="['bxs-navbar', {
        'bxs-navbar-mobile': !!$store.state.is_mobile
    }]">
        <bxs-layout>
            <!-- desktop nav -->
            <bxs-row
            v-if="!$store.state.is_mobile"
            align="center"
            class="mb-ui">
                <bxs-col cols="6">
                    <logo />
                </bxs-col>

                <bxs-col
                cols="6"
                class="mx-auto text-right">
                    <bxs-chip
                    v-if="$store.state.ghost_user"
                    class="mr-ui">
                        <bxs-icon
                        name="person-fill"
                        class="text-success mr-2"></bxs-icon>
                        <small style="font-size: 10px;">{{ $store.state.ghost_user }}</small>
                    </bxs-chip>

                    <bxs-menu
                    activator-icon="three-dots-vertical"
                    close-on-click>
                        <bxs-list class="mb-ui">
                            <bxs-list-item
                            prepend-icon="house-fill"
                            to="/">Home</bxs-list-item>

                            <bxs-list-item
                            v-if="!$leila.auth.me"
                            prepend-icon="person-fill"
                            to="/signin">Accedi</bxs-list-item>

                            <bxs-list-item
                            prepend-icon="search"
                            to="/search">Cerca</bxs-list-item>

                            <span v-if="!!$leila.auth.me">
                                <bxs-list-item
                                prepend-icon="person-fill"
                                to="/me">Il mio profilo</bxs-list-item>

                                <bxs-list-item
                                prepend-icon="arrow-right"
                                to="/me/orders">I miei prestiti</bxs-list-item>
                            </span>

                            <!-- <bxs-menu
                            title="Fai sapere di Leila"
                            block>
                                <template #activator="{ on }">
                                    <bxs-list-item
                                    v-on="on"
                                    prepend-icon="share-fill"
                                    class="mb-0">Condividi su</bxs-list-item>
                                </template>

                                <bxs-list title="Condividi">
                                    <bxs-list-item
                                    append-icon="whatsapp"
                                    spacer
                                    @click="share('whatsapp')">Condividi su</bxs-list-item>

                                    <bxs-list-item
                                    append-icon="fb"
                                    spacer
                                    @click="share('fb')">Condividi su</bxs-list-item>

                                    <bxs-list-item
                                    append-icon="tw"
                                    spacer
                                    @click="share('tw')">Condividi su</bxs-list-item>
                                </bxs-list>
                            </bxs-menu> -->

                            <bxs-list-item
                            prepend-icon="info-circle-fill"
                            to="/help"
                            class="mb-0">Bisogno di aiuto?</bxs-list-item>

                            <bxs-list-item
                            v-if="$store.state.library && $store.state.library.website"
                            prepend-icon="arrow-top-right"
                            :href="$store.state.library.website">Visita il nostro sito</bxs-list-item>

                            <bxs-list-item
                            v-if="!!$leila.auth.me"
                            prepend-icon="arrow-left"
                            @click="signout">Esci</bxs-list-item>
                        </bxs-list>

                        <bxs-footer class="mt-12 px-ui" />
                    </bxs-menu>
                </bxs-col>
            </bxs-row>

            <!-- app nav -->
            <div v-else>
                <!-- navbar -->
                <bxs-toolbar
                flat
                light
                max-width="90%">
                    <img
                    src="/img/logo-typo-light.png"
                    alt="Leila Italia Logo"
                    style="max-width: 50px;"
                    @click="$router.push('/').catch(() => {})">

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    to="/">
                        <bxs-icon :name="$route.name === 'home' ? 'house-fill' : 'house'"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    :color="$route.name === 'search' ? 'white' : null"
                    fab
                    to="/search">
                        <bxs-icon name="search"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    :color="$route.name === 'me' || $route.name === 'signin' ? 'white' : ''"
                    to="/me">
                        <bxs-icon :name="$route.name === 'me' || $route.name === 'signin' ? 'person-fill' : 'person'"></bxs-icon>
                    </bxs-btn>

                    <bxs-spacer></bxs-spacer>

                    <bxs-btn
                    icon
                    fab
                    to="/help">
                        <bxs-icon viewbox="0 0 22 22">
                            <path
                            v-if="$route.name === 'help'"
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />

                            <g v-else>
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </g>
                        </bxs-icon>
                    </bxs-btn>

                    <!-- <bxs-spacer></bxs-spacer>

                    <bxs-menu title="Fai sapere di Leila">
                        <template #activator="{ on }">
                            <bxs-btn
                            v-on="on"
                            icon
                            fab>
                                <bxs-icon name="share-fill"></bxs-icon>
                            </bxs-btn>
                        </template>

                        <bxs-list title="Condividi">
                            <bxs-list-item
                            v-if="$store.state.is_mobile"
                            append-icon="whatsapp"
                            spacer
                            @click="share('whatsapp')">Condividi su</bxs-list-item>

                            <bxs-list-item
                            append-icon="fb"
                            spacer
                            @click="share('fb')">Condividi su</bxs-list-item>

                            <bxs-list-item
                            append-icon="tw"
                            spacer
                            @click="share('tw')">Condividi su</bxs-list-item>
                        </bxs-list>
                    </bxs-menu> -->
                </bxs-toolbar>
            </div>
        </bxs-layout>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar',
    props: {
        access: {
            type: [String, Object, Boolean],
            required: false,
            default: null
        }
    },
    methods: {
        async signout () {
            await this.$leila.auth.signout()
            sessionStorage.removeItem('lel_ghost_user')
            this.$router.go()
        },
        share (social) {
            const left = (screen.width - 570) / 2
            const top = (screen.height - 570) / 2
            const params = 'menubar=no,toolbar=no,status=no,width=570,height=570,top=' + top + ',left=' + left
            let url = null

            if (social === 'tw') {
                url = `https://twitter.com/share?text=${document.title} | Abbiamo bisogno di utilizzare, non di condividere&url=${window.location.href}`
            } else if (social === 'fb') {
                url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
            } else if (social === 'whatsapp') {
                url = `whatsapp://send?text=${document.title} | Abbiamo bisogno di utilizzare, non di condividere. ${window.location.href}`
            }

            // if (this.$store.state.is_touch_device) window.open(url, '_blank')
            window.open(url, 'TwitterWindow', params)
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-navbar {
    position: relative;
    width: 100%;
    min-height: var(--navbar-height);
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    border-top-left-radius: calc(var(--radius) * 2);
    border-top-right-radius: calc(var(--radius) * 2);

    &.bxs-navbar-mobile {
        position: fixed;
        width: 100%;
        min-height: var(--toolbar-height);
        bottom: 0; // var(--size-layout);
        left: 0;
        // background-color: transparent;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        z-index: var(--layer-navbar);
        background-color: var(--navbar-background-color);

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: calc(100% + var(--navbar-height));
        //     background-image: var(--navbar-backdrop-gradient);
        //     z-index: -1;
        // }

        .bxs-layout {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>